<template>
  <v-container id="dashboard" fluid tag="section">
    <template>
      <div class="d-flex mb-4 mt-4">
        <v-btn color="primary" class="mr-0" @click="handleImportBtn">
          <v-icon class="mr-1">mdi-upload</v-icon> Import</v-btn
        >

        <v-menu
          bottom
          left
          offset-y
          origin="top right"
          transition="scale-transition"
          v-if="this.getUserDetails.user_type === 'admin'"
        >
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              class="ml-2"
              color="#7F7F7F"
              style="background-color: white; border: 1px solid #7f7f7f"
              min-width="0"
              text
              v-bind="attrs"
              v-on="on"
            >
              Bulk Actions
              <v-icon>mdi-menu-down</v-icon>
            </v-btn>
          </template>

          <v-list :tile="false" nav>
            <!-- <div>
              <app-bar-item>
                <v-btn
                  background="transparent"
                  style="
                    background-color: transparent !important;
                    border: none;
                    color: #7f7f7f;
                    box-shadow: none;
                  "
                  @click="
                    () => {
                      handleSelectDelete('delete');
                    }
                  "
                >
                  Delete Selected
                </v-btn>
              </app-bar-item>
            </div> -->
            <div>
              <app-bar-item>
                <v-btn
                  background="transparent"
                  color="black"
                  @click="
                    () => {
                      handleSelectDelete('archive');
                    }
                  "
                  style="
                    background-color: transparent !important;
                    border: none;
                    color: #7f7f7f;
                    box-shadow: none;
                  "
                >
                  Archive Selected
                </v-btn>
              </app-bar-item>
            </div>
          </v-list>
        </v-menu>
        <v-btn
          color="primary"
          @click="
            () => {
              dialogGenerateReport = true;
            }
          "
          class="mr-0 ml-auto"
          ><v-icon class="mr-2">mdi-clipboard-text-outline</v-icon> Generate
          Report</v-btn
        >
      </div>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="tableData"
        :items-per-page="itemsPerPage"
        hide-default-footer
        show-select
        :loading="isLoading"
        loading-text="Loading... Please wait"
        class="elevation-1"
      >
        <template v-slot:item.status="{ item }">
          <v-chip
            style="width: 100%; justify-content: center"
            :color="
              item.status === 'uploaded' || item.status === 'scheduled'
                ? 'orange'
                : item.status === 'sent' ||
                  item.status === 'delivered' ||
                  item.status === 'accepted' ||
                  item.status === 'replied'
                ? 'green'
                : 'red'
            "
            dark
          >
            <p class="mb-0" style="text-transform: capitalize; font-size: 12px">
              {{ item.status }}
            </p>
          </v-chip>
        </template>
        <template v-slot:item.sms_replied="{ item }">
          <v-chip
            style="width: 62px"
            class="p-2 pl-5"
            :color="item.sms_replied === 1 ? '#4CAF50' : '#AB1713'"
            dark
          >
            <v-icon v-if="item.sms_replied === 1">mdi-check</v-icon>
            <v-icon v-if="item.sms_replied !== 1">mdi-window-close</v-icon>
          </v-chip>
        </template>
        <template v-slot:top>
          <v-dialog v-model="dialog" max-width="900px">
            <v-card>
              <v-card-title class="elevation-3 pb-3 text-left">
                <v-text
                  class="h6 font-weight-bold d-flex items-center item-center"
                  style="align-items: center"
                >
                  <img width="32" class="mr-2" src="/Group.svg" />
                  <v-text
                    class="modal-header-text font-weight-medium align-center item-center"
                    >Member Details</v-text
                  >
                  <v-chip
                    style="justify-content: center; margin-left: auto"
                    :color="
                      viewItem.status === 'uploaded' ||
                      viewItem.status === 'scheduled'
                        ? 'orange'
                        : viewItem.status === 'sent' ||
                          viewItem.status === 'delivered' ||
                          viewItem.status === 'accepted' ||
                          viewItem.status === 'replied'
                        ? 'green'
                        : 'red'
                    "
                    dark
                  >
                    <p
                      class="mb-0"
                      style="text-transform: capitalize; font-size: 12px"
                    >
                      {{ viewItem.status }}
                    </p>
                  </v-chip>
                </v-text>
              </v-card-title>

              <v-card-text class="pt-6 member-detail-dialog">
                <v-container>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col col-md-6>
                          <v-text class="font-weight-medium"
                            >Member Number</v-text
                          >
                        </v-col>
                        <v-col col-md-6>
                          <v-text
                            class="font-weight-medium"
                            style="color: #7f7f7f"
                            >{{ viewItem.member_no }}</v-text
                          >
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col col-md-6>
                          <v-text class="font-weight-medium h5"
                            >First name</v-text
                          >
                        </v-col>
                        <v-col col-md-6>
                          <v-text
                            class="font-weight-medium"
                            style="color: #7f7f7f"
                            >{{ viewItem.firstname }}</v-text
                          >
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col col-md-6>
                          <v-text class="font-weight-medium">Surname</v-text>
                        </v-col>
                        <v-col col-md-6>
                          <v-text
                            class="font-weight-medium"
                            style="color: #7f7f7f"
                            >{{ viewItem.lastname }}</v-text
                          >
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col col-md-6>
                          <v-text class="font-weight-medium"
                            >Phone Number</v-text
                          >
                        </v-col>
                        <v-col col-md-6>
                          <v-text
                            class="font-weight-medium"
                            style="color: #7f7f7f"
                            >{{ viewItem.phone }}</v-text
                          >
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col col-md-6>
                          <v-text class="font-weight-medium">Region</v-text>
                        </v-col>
                        <v-col col-md-6>
                          <v-text
                            class="font-weight-medium"
                            style="color: #7f7f7f"
                            >{{ viewItem.region }}</v-text
                          >
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col col-md-6>
                          <v-text class="font-weight-medium">Batch ID</v-text>
                        </v-col>
                        <v-col col-md-6>
                          <v-text
                            class="font-weight-medium"
                            style="color: #7f7f7f"
                            >{{ viewItem.batch_id }}</v-text
                          >
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col col-md-6>
                          <v-text class="font-weight-medium"
                            >Uploaded on</v-text
                          >
                        </v-col>
                        <v-col col-md-6>
                          <v-text
                            class="font-weight-medium"
                            style="color: #7f7f7f"
                            >{{ viewItem.createdon }}</v-text
                          >
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col col-md-6>
                          <v-text class="font-weight-medium"
                            >Scheduled on</v-text
                          >
                        </v-col>
                        <v-col col-md-6>
                          <v-text
                            class="font-weight-medium"
                            style="color: #7f7f7f"
                            >{{ viewItem.scheduledon }}</v-text
                          >
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col col-md-6>
                          <v-text class="font-weight-medium">Sent on</v-text>
                        </v-col>
                        <v-col col-md-6>
                          <v-text
                            class="font-weight-medium"
                            style="color: #7f7f7f"
                            >{{ viewItem.senton }}</v-text
                          >
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col col-md-6>
                          <v-text class="font-weight-medium"
                            >1st Resend on</v-text
                          >
                        </v-col>
                        <v-col col-md-6>
                          <v-text
                            class="font-weight-medium"
                            style="color: #7f7f7f"
                            >{{ viewItem.resenton1 }}</v-text
                          >
                        </v-col> </v-row
                      ><v-row>
                        <v-col col-md-6>
                          <v-text class="font-weight-medium"
                            >2nd Resend on</v-text
                          >
                        </v-col>
                        <v-col col-md-6>
                          <v-text
                            class="font-weight-medium"
                            style="color: #7f7f7f"
                            >{{ viewItem.resenton2 }}</v-text
                          >
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col col-md-6>
                          <v-text class="font-weight-medium"
                            >3rd Resend on</v-text
                          >
                        </v-col>
                        <v-col col-md-6>
                          <v-text
                            class="font-weight-medium"
                            style="color: #7f7f7f"
                            >{{ viewItem.resenton3 }}</v-text
                          >
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col col-md-6>
                          <v-text class="font-weight-medium">Replied on</v-text>
                        </v-col>
                        <v-col col-md-6>
                          <v-text
                            class="font-weight-medium"
                            style="color: #7f7f7f"
                            >{{ viewItem.repliedon }}</v-text
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col col-md-6>
                          <v-text class="font-weight-medium">Send Count</v-text>
                        </v-col>
                        <v-col col-md-6>
                          <v-text class="font-weight-medium">{{
                            viewItem.send_cnt
                          }}</v-text>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col col-md-6>
                          <v-text class="font-weight-medium">Scheduled</v-text>
                        </v-col>
                        <v-col col-md-6>
                          <v-chip
                            :color="
                              viewItem.sms_scheduled === 1
                                ? '#4CAF50'
                                : '#AB1713'
                            "
                            style="width: 62px"
                            class="p-2 pl-5"
                            dark
                          >
                            <v-icon v-if="viewItem.sms_scheduled === 1"
                              >mdi-check</v-icon
                            >
                            <v-icon v-if="viewItem.sms_scheduled === 0"
                              >mdi-close</v-icon
                            >
                          </v-chip>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col col-md-6>
                          <v-text class="font-weight-medium">Sent</v-text>
                        </v-col>
                        <v-col col-md-6>
                          <v-chip
                            :color="
                              viewItem.sms_sent === 1 ? '#4CAF50' : '#AB1713'
                            "
                            style="width: 62px"
                            class="p-2 pl-5"
                            dark
                          >
                            <v-icon v-if="viewItem.sms_sent === 1"
                              >mdi-check</v-icon
                            >
                            <v-icon v-if="viewItem.sms_sent === 0"
                              >mdi-close</v-icon
                            >
                          </v-chip>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col col-md-6>
                          <v-text class="font-weight-medium">Resend 1</v-text>
                        </v-col>
                        <v-col col-md-6>
                          <v-chip
                            :color="
                              viewItem.resend1_sent !== 0
                                ? '#4CAF50'
                                : '#AB1713'
                            "
                            style="width: 62px"
                            class="p-2 pl-5"
                            dark
                          >
                            <v-icon v-if="viewItem.resend1_sent !== 0"
                              >mdi-check</v-icon
                            >
                            <v-icon v-if="viewItem.resend1_sent === 0"
                              >mdi-close</v-icon
                            >
                          </v-chip>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col col-md-6>
                          <v-text class="font-weight-medium">Resend 2</v-text>
                        </v-col>
                        <v-col col-md-6>
                          <v-chip
                            :color="
                              viewItem.resend2_sent !== 0
                                ? '#4CAF50'
                                : '#AB1713'
                            "
                            style="width: 62px"
                            class="p-2 pl-5"
                            dark
                          >
                            <v-icon v-if="viewItem.resend2_sent !== 0"
                              >mdi-check</v-icon
                            >
                            <v-icon v-if="viewItem.resend2_sent === 0"
                              >mdi-close</v-icon
                            >
                          </v-chip>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col col-md-6>
                          <v-text class="font-weight-medium">Resend 3</v-text>
                        </v-col>
                        <v-col col-md-6>
                          <v-chip
                            :color="
                              viewItem.resend3_sent !== 0
                                ? '#4CAF50'
                                : '#AB1713'
                            "
                            style="width: 62px"
                            class="p-2 pl-5"
                            dark
                          >
                            <v-icon v-if="viewItem.resend3_sent !== 0"
                              >mdi-check</v-icon
                            >
                            <v-icon v-if="viewItem.resend3_sent === 0"
                              >mdi-close</v-icon
                            >
                          </v-chip>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col col-md-6>
                          <v-text class="font-weight-medium">Replied</v-text>
                        </v-col>
                        <v-col col-md-6>
                          <v-chip
                            :color="
                              viewItem.sms_replied === 1 ? '#4CAF50' : '#AB1713'
                            "
                            style="width: 62px"
                            class="p-2 pl-5"
                            dark
                          >
                            <v-icon v-if="viewItem.sms_replied === 1"
                              >mdi-check</v-icon
                            >
                            <v-icon v-if="viewItem.sms_replied === 0"
                              >mdi-close</v-icon
                            >
                          </v-chip>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col col-md-6>
                          <v-text class="font-weight-medium">Emailed</v-text>
                        </v-col>
                        <v-col col-md-6>
                          <v-chip
                            :color="
                              viewItem.email_sent === 1 ? '#4CAF50' : '#AB1713'
                            "
                            style="width: 62px"
                            class="p-2 pl-5"
                            dark
                          >
                            <v-icon v-if="viewItem.email_sent === 1"
                              >mdi-check</v-icon
                            >
                            <v-icon v-if="viewItem.email_sent === 0"
                              >mdi-close</v-icon
                            >
                          </v-chip>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col col-md-6>
                          <v-text class="font-weight-medium">Error code</v-text>
                        </v-col>
                        <v-col col-md-6>
                          <v-text
                            class="font-weight-medium text-center"
                            :style="[
                              viewItem.error_code === 0
                                ? { color: '#ab1713' }
                                : { color: 'green' },
                            ]"
                            >{{ viewItem.error_code }}</v-text
                          >
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col col-md-6>
                          <v-text class="font-weight-medium">Details</v-text>
                        </v-col>
                        <v-col col-md-6>
                          <v-text
                            class="font-weight-medium"
                            style="color: #7f7f7f"
                            >{{ viewItem.details }}</v-text
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions style="justify-content: center">
                <v-btn @click="close" color="primary"> Close </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <Upload
            :dialog.sync="uploadDialog"
            :multiple="false"
            @refresh="refresh"
          />
          <v-dialog v-model="uploadResultDialog" max-width="900px">
            <v-card>
              <v-card-title class="elevation-3 pb-3 text-left">
                <v-text
                  class="h6 font-weight-bold d-flex items-center item-center"
                  style="align-items: center"
                >
                  <img width="32" class="mr-2" src="/uploadIcon.png" />
                  <v-text
                    class="modal-header-text font-weight-medium align-center item-center"
                    >Import</v-text
                  >
                </v-text>
              </v-card-title>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="default" @click="close"> Close </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="error" @click="deleteItemConfirm">Delete</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogSelectDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Are you sure you want to
                {{ selectedItemType === "archive" ? "archive" : "delete" }}
                selected items?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeSelectDelete"
                  >Cancel</v-btn
                >
                <v-btn color="error" @click="deleteSelectItemConfirm">{{
                  selectedItemType === "archive" ? "Archive" : "Delete"
                }}</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <Generate :dialog.sync="dialogGenerateReport" />
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2 action_icon" @click="editItem(item)">
            mdi-information-variant
          </v-icon>
          <!-- <v-icon class="action_icon" small @click="deleteItem(item)">
            mdi-delete
          </v-icon> -->
        </template>
      </v-data-table>
      <Pagination
        :page.sync="page"
        :itemsPerPage.sync="itemsPerPage"
        :pages="pages"
        :totalNumberOfItems="totalNumberOfItems"
        :pageItems="pageItems"
      />
    </template>
    <v-snackbar
      :timeout="3000"
      :value="notification"
      absolute
      right
      top
      tile
      :color="notificationColor"
    >
      {{ notificationTxt }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  APIdoGetMembers,
  APIdoMultiRemoveMember,
  APIdoRemoveMember,
  APIdoMultiArchiveMember,
} from "@/api/common/api";
import Upload from "./component/dashboard/UploadDialog.vue";
import Generate from "./component/dashboard/GeneateModal.vue";
import Pagination from "./component/pagination/";

export default {
  name: "DashboardDashboard",

  data() {
    return {
      isLoading: false,
      totalNumberOfItems: 50,
      page: 1,
      itemsPerPage: 100,
      pages: 1,
      pageItems: [100, 200, 300],
      notification: false,
      notificationTxt: "",
      notificationColor: "",
      dialog: false,
      dialogDelete: false,
      dialogSelectDelete: false,
      selectedItemType: "archive",
      dialogGenerateReport: false,
      editedIndex: -1,
      viewItem: {},
      uploadResultDialog: false,
      uploadDialog: false,
      selected: [],
      headers: [
        {
          text: "Member Number",
          value: "member_no",
        },
        { text: "FIRST NAME", value: "firstname" },
        { text: "SURNAME", value: "lastname" },
        { text: "PHONE NUMBER", value: "phone" },
        { text: "REGION", value: "region" },
        { text: "SEND STATUS", value: "status" },
        { text: "REPLY STATUS", value: "sms_replied" },
        { text: "CREATED ON", value: "createdon" },
        { text: "", value: "actions" },
      ],
      tableData: [],
    };
  },
  components: {
    Upload,
    Generate,
    Pagination,
  },
  mounted() {
    this.getMembers(this.page, this.itemsPerPage);
    this.doGetSettings();
    this.doGetMessages();
    this.doGetSchedules();
  },

  computed: {
    ...mapGetters({
      getUserDetails: "getUserData",
    }),
  },

  methods: {
    ...mapActions({
      doGetSettings: "doGetSettings",
      doGetMessages: "doGetMessages",
      doGetSchedules: "doGetSchedules",
    }),
    getMembers(page, limit) {
      this.isLoading = true;
      this.tableData = [];
      APIdoGetMembers(page, limit)
        .then((response) => {
          this.isLoading = false;
          this.pages = response.data.pages;
          this.totalNumberOfItems = response.data.total;
          this.tableData = response.data.results;
        })
        .catch((error) => {
          if (error.response.data.status === "unauthorized") {
            localStorage.removeItem("user");
            this.$router.push("/");
          }
        });
    },
    handleItemsPerPageChange() {
      this.page = 1;
      this.getMembers(this.page, this.itemsPerPage);
    },
    handlePageChange() {
      this.page = parseInt(this.page);
      this.getMembers(this.page, this.itemsPerPage);
    },
    refresh() {
      this.page = 1;
      this.itemsPerPage = 100;
      this.getMembers(this.page, this.itemsPerPage);
    },
    handleImportBtn() {
      this.uploadDialog = true;
    },

    editItem(item) {
      this.editedIndex = this.tableData.indexOf(item);
      this.viewItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.tableData.indexOf(item);
      this.viewItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.handleDeleteItem(this.viewItem.id);
      this.closeDelete();
    },

    handleDeleteItem(id) {
      const index = this.editedIndex;
      APIdoRemoveMember(id)
        .then((response) => {
          this.tableData.splice(index, 1);
        })
        .catch((error) => {
          if (error.response.data.status === "unauthorized") {
            localStorage.removeItem("user");
            this.$router.push("/");
          }
        });
    },

    handleSelectDelete(type) {
      if (this.selected.length > 0) {
        this.dialogSelectDelete = true;
        this.selectedItemType = type;
      } else {
        this.notification = true;
        this.notificationTxt = "You have not selected any members ";
        this.notificationColor = "error";
        setTimeout(() => {
          this.notification = false;
        }, 2500);
      }
    },
    deleteSelectItemConfirm() {
      this.closeSelectDelete();
      let data = [];
      for (let i = 0; i < this.selected.length; i++) {
        this.editedIndex = this.tableData.indexOf(this.selected[i]);
        data.push(this.selected[i].id);
      }
      if (this.selectedItemType === "delete") {
        APIdoMultiRemoveMember(data)
          .then((response) => {
            this.notification = true;
            this.notificationTxt = response.data.msg;
            this.notificationColor = "success";
            this.getMembers(this.page, this.itemsPerPage);
          })
          .catch((error) => {
            if (error.response.data.status === "unauthorized") {
              localStorage.removeItem("user");
              this.$router.push("/");
            }
          });
      } else {
        APIdoMultiArchiveMember(data)
          .then((response) => {
            this.notification = true;
            this.notificationTxt = response.data.msg;
            this.notificationColor = "success";
            this.getMembers(this.page, this.itemsPerPage);
          })
          .catch((error) => {
            if (error.response.data.status === "unauthorized") {
              localStorage.removeItem("user");
              this.$router.push("/");
            }
          });
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.viewItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.viewItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeSelectDelete() {
      this.dialogSelectDelete = false;
      this.$nextTick(() => {
        this.selected = [];
      });
    },
  },
};
</script>
