<template>
  <v-dialog @click:outside="closeDialog" :value="dialog" max-width="850px">
    <v-card
      @dragover.prevent
      @drop.prevent
      :class="{ 'grey lighten-2': dragover }"
    >
      <v-card-title class="elevation-3 pb-3 text-left">
        <v-text
          class="h6 font-weight-bold d-flex items-center item-center align-center self-center"
        >
          <img width="32" class="mr-2" src="/uploadIcon.png" />
          <v-text
            class="modal-header-text font-weight-medium align-center item-center"
            >Import</v-text
          >
        </v-text>
      </v-card-title>
      <v-card-text class="uploadSection" @drop="dragFile" v-if="!uploadFlag">
        <v-radio-group
          v-model="leaver_code"
          mandatory
          row
          solo
          dense
          class="mt-1"
        >
          <v-radio label="Direct Debit" value="DD"></v-radio>
          <v-radio label="Apparent Leavers" value="AL"></v-radio>
        </v-radio-group>
        <v-row
          class="d-flex flex-column"
          dense
          align="center"
          justify="center"
          style="border: 1px dashed gray"
        >
          <v-icon :class="[dragover ? 'mt-2, mb-6' : 'mt-5']" size="60">
            mdi-cloud-upload
          </v-icon>
          <p>
            Drag and drop or
            <v-text
              color="#1867c0"
              text-decoration="underline"
              style="
                color: #1867c0;
                text-decoration: underline;
                cursor: pointer;
              "
              @click="handleFileImport"
            >
              browse</v-text
            >
          </p>
          <input
            ref="uploader"
            class="d-none"
            type="file"
            @change="onFileChanged"
          />
          <v-text>{{ File.name }}</v-text>
        </v-row>
        <div v-if="uploadError" class="mt-3 errorSection d-flex">
          <div>
            <v-icon color="white" size="20"> mdi-alert-circle-outline </v-icon>
          </div>
          <v-text class="ml-2"
            >This specified file {{ File.name }} could not be uploaded. Only
            files with the following extensions are allowed csv.</v-text
          >
        </div>
      </v-card-text>
      <v-card-text class="resultSection" v-else-if="uploadFlag">
        <v-row>
          <v-col cols="12" md="6">
            <v-text class="titleLabel">Total Added</v-text>
            <v-text class="valueLabel">{{ uploadResponse.total }}</v-text>
          </v-col>
          <v-col cols="12" md="6">
            <v-text class="titleLabel">Inserted</v-text>
            <v-text class="valueLabel">{{ uploadResponse.inserted }}</v-text>
          </v-col>
          <v-col cols="12" md="6">
            <v-text class="titleLabel">Scheduled</v-text>
            <v-text class="valueLabel">{{ uploadResponse.scheduled }}</v-text>
          </v-col>
          <v-col cols="12" md="6">
            <v-text class="titleLabel">Errors</v-text>
            <v-text class="valueLabel">{{ uploadResponse?.error_cnt }}</v-text>
          </v-col>
          <v-col cols="12" md="12">
            <v-text class="titleLabel">File Errors</v-text>
            <div class="errors mt-2">
              <p v-for="error in uploadResponse.errors" :key="error">
                {{ error }}
              </p>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions style="justify-content: center">
        <v-btn
          color="primary"
          :disabled="!File.size || uploadError"
          @click="submit"
          :loading="isLoading"
          v-if="!uploadFlag"
        >
          Upload
        </v-btn>
        <v-btn @click="closeDialog" :color="uploadFlag ? 'primary' : ''">
          {{ uploadFlag ? "Ok" : "Cancel" }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar
      :timeout="3000"
      :value="notification"
      absolute
      right
      top
      tile
      :color="notificationColor"
    >
      {{ notificationTxt }}
    </v-snackbar>
  </v-dialog>
</template>

<script>
import { APIdoUploadMembers } from "@/api/common/api";
import "./UploadDialog.scss";
export default {
  name: "Upload",
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      File: {},
      dragover: false,
      isLoading: false,
      uploadFlag: false,
      uploadError: false,
      uploadErrorText: "",
      uploadResponse: {},
      notification: false,
      notificationTxt: "",
      notificationColor: "",
      leaver_code: "",
    };
  },
  mounted() {
    this.uploadFlag = false;
    this.uploadError = false;
    this.uploadErrorText = "";
    this.uploadResponse = {};
    this.isLoading = false;
    this.File = {};
  },
  methods: {
    closeDialog() {
      // Remove all the uploaded files
      this.uploadFlag = false;
      this.uploadError = false;
      this.uploadErrorText = "";
      this.uploadResponse = {};
      this.File = {};
      this.isLoading = false;
      // Close the dialog box
      this.$emit("update:dialog", false);
    },

    submit() {
      const formData = new FormData();
      formData.append("upload", this.File);
      formData.append("leaver_code", this.leaver_code);

      this.isLoading = true;

      APIdoUploadMembers(formData)
        .then((response) => {
          this.uploadFlag = true;
          this.uploadResponse = response.data;
          this.$emit("refresh");
        })
        .catch((error) => {
          this.file = {};
          this.$refs.uploader.value = null;
          this.uploadFlag = false;
          this.isLoading = false;
          this.uploadError = true;
          this.notification = true;
          this.notificationTxt = "There is an error.";
          this.notificationColor = "error";
          this.uploadErrorText = "File type is not allowed.";
        });
    },

    handleFileImport() {
      this.isSelecting = true;

      // After obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      // Trigger click on the FileInput
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      const file = e.target.files[0];
      this.File = file;
      this.uploadError = false;
      this.uploadErrorText = "";
    },
    dragFile(e) {
      this.File = e.dataTransfer.files[0];
    },
  },
};
</script>
