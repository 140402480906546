<template>
  <v-dialog @click:outside="closeDialog" :value="dialog" max-width="1000px">
    <v-card>
      <v-card-title class="elevation-3 pb-3 text-left">
        <v-text
          class="h6 font-weight-bold d-flex items-center item-center"
          style="align-items: center"
        >
          <v-text
            class="modal-header-text font-weight-medium align-center item-center"
            >Report</v-text
          >
        </v-text>
      </v-card-title>

      <v-card-text>
        <v-container class="mt-6">
          <v-row>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" sm="4" class="mt-3">
                  <v-text style="color: black; font-size: 16px"
                    >Date from</v-text
                  >
                </v-col>
                <v-col cols="12" sm="8">
                  <v-text-field
                    style="max-width: 250px"
                    dense
                    solo
                    type="datetime-local"
                    placeholder="london@gmb.org.uk"
                    v-model="date_from"
                  />
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" sm="4" class="mt-3">
                  <v-text style="color: black; font-size: 16px"
                    >First name
                  </v-text>
                </v-col>
                <v-col cols="12" sm="8" class="d-flex">
                  <v-text-field
                    v-model="firstname"
                    style="max-width: 250px"
                    dense
                    solo
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" sm="4" class="mt-3">
                  <v-text style="color: black; font-size: 16px">Date to</v-text>
                </v-col>
                <v-col cols="12" sm="8">
                  <v-text-field
                    style="max-width: 250px"
                    dense
                    solo
                    type="datetime-local"
                    v-model="date_to"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" sm="4" class="mt-3">
                  <v-text style="color: black; font-size: 16px"
                    >Surname
                  </v-text>
                </v-col>
                <v-col cols="12" sm="8" class="d-flex">
                  <v-text-field
                    style="max-width: 250px"
                    dense
                    solo
                    v-model="lastname"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" sm="4" class="mt-3">
                  <v-text style="color: black; font-size: 16px">Status</v-text>
                </v-col>
                <v-col cols="12" sm="8" class="d-flex">
                  <v-select
                    :items="statusItems"
                    label="Status"
                    :item-value="'val'"
                    :item-text="'text'"
                    multiple
                    solo
                    dense
                    style="max-width: 250px"
                    v-model="status"
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" sm="4" class="mt-3">
                  <v-text style="color: black; font-size: 16px">Email </v-text>
                </v-col>
                <v-col cols="12" sm="8" class="d-flex">
                  <v-text-field
                    style="max-width: 250px"
                    dense
                    solo
                    v-model="email"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" sm="4" class="mt-3">
                  <v-text style="color: black; font-size: 16px">Region</v-text>
                </v-col>
                <v-col cols="12" sm="8" class="d-flex">
                  <v-select
                    :items="regionItems"
                    :item-value="'val'"
                    :item-text="'text'"
                    v-model="region_code"
                    label="Region"
                    solo
                    multiple
                    style="max-width: 250px"
                    dense
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" sm="4" class="mt-3">
                  <v-text style="color: black; font-size: 16px"
                    >Batch ID
                  </v-text>
                </v-col>
                <v-col cols="12" sm="8" class="d-flex">
                  <v-text-field
                    style="max-width: 250px"
                    dense
                    solo
                    v-model="batch_id"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" sm="4" class="mt-3">
                  <v-text style="color: black; font-size: 16px"
                    >Member No</v-text
                  >
                </v-col>
                <v-col cols="12" sm="8" class="d-flex">
                  <v-text-field
                    style="max-width: 250px"
                    dense
                    solo
                    v-model="member_no"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" sm="4" class="mt-3">
                  <v-text style="color: black; font-size: 16px"
                    >Include Archived</v-text
                  >
                </v-col>
                <v-checkbox class="mt-5" v-model="archived"></v-checkbox>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="isLoading"
          @click="handleGenerateReport"
        >
          Generate Report
        </v-btn>
        <v-btn color="default" @click="closeDialog"> Cancel </v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar
      :timeout="3000"
      :value="notification"
      absolute
      right
      top
      tile
      :color="notificationColor"
    >
      {{ notificationTxt }}
    </v-snackbar>
  </v-dialog>
</template>

<script>
import { APIdoGenerateReport } from "@/api/common/api";
// import "./GenerateModal.scss";
export default {
  name: "Upload",
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      statusItems: [
        { val: "scheduled", text: "Scheduled" },
        { val: "sent", text: "Sent" },
        { val: "delivered", text: "Delivered" },
        { val: "accepted", text: "Accepted" },
        { val: "failed", text: "Failed" },
        { val: "undelivered", text: "Undelivered" },
        { val: "rejected", text: "Rejected" },
        { val: "expired", text: "Expired" },
        { val: "replied", text: "Replied" },
      ],
      regionItems: [
        { val: "SW", text: "Wales & South West" },
        { val: "SO", text: "Southern" },
        { val: "SC", text: "Scotland" },
        { val: "NW", text: "North West" },
        { val: "NO", text: "Northern" },
        { val: "MI", text: "Midland" },
        { val: "LO", text: "London" },
      ],
      date_from: "",
      date_to: "",
      member_no: "",
      firstname: "",
      lastname: "",
      email: "",
      batch_id: "",
      archived: 1,
      status: [],
      region_code: [],
      isLoading: false,
      notification: false,
      notificationTxt: "",
      notificationColor: "",
    };
  },
  mounted() {},
  methods: {
    closeDialog() {
      this.$emit("update:dialog", false);
    },
    s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    },
    handleGenerateReport() {
      let postData = {
        date_from: this.date_from,
        date_to: this.date_to,
        member_no: this.member_no,
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
        batch_id: this.batch_id,
        archived: this.archived ? 1 : 0,
        status: JSON.stringify(this.status),
        region_code: JSON.stringify(this.region_code),
      };
      this.isLoading = true;
      APIdoGenerateReport(postData)
        .then((response) => {
          this.isLoading = false;
          const disposition = response.headers["content-disposition"];
          var filename = "";
          if (disposition && disposition.indexOf("attachment") !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, "");
            }
          }
          let BLOB = new Blob([response.data], {
            type: "application/ vnd.openxmlformats - officedocument.spreadsheetml.sheet ; charset = UTF-8 ",
          }); // set the file type for blob, taking. Xlsx as an example
          let url = window.URL.createObjectURL(BLOB); // create a temporary URL to point to the blob object
          let a = document.createElement("a");
          a.href = url;
          a.download = filename;
          a.click();
          //Release the temporary object URL
          window.URL.revokeObjectURL(url);
          this.closeDialog();
        })
        .catch((error) => {
          if (error.response.data.status === "unauthorized") {
            localStorage.removeItem("user");
            this.$router.push("/");
          } else if (error.response.data.error.code === 422) {
            this.isLoading = false;
            this.notification = true;
            this.notificationTxt = error.message;
            this.notificationColor = "error";
          }
        });
    },
  },
};
</script>
